export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return;

  if (
    window.location.hostname.startsWith("demo") ||
    window.location.hostname.startsWith("demos")
  ) {
    return navigateTo({
      path: "/auth/demo-login",
      replace: true,
    });
  }
});
